import React, { useMemo, useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//Popup for adding holiday
const Add_holiday = (props) => {
  // for Translation

  const { t } = useTranslation();
  const addholiday = useSelector((state) => state.Reducer);

  // add holiday popup start
  const signUpSchema = Yup.object({
    name: Yup.string().required("Enter Holiday Name"),
    date: Yup.string().required("Enter Branch Date"),
    branch_yesorno: Yup.string().required("Select Yes or No"),
    description: Yup.string().required("Enter Description"),
    notifyEmployees: Yup.string(),
    branchname: Yup.array().of(Yup.string()),
  });
  const [branches, setBranches] = useState([]);
  const [renderKey, setRenderKey] = useState(0);
  const [initialValues, setinitialValues] = useState({
    name: "",
    date: "",
    branch_yesorno: "",
    description: "",
    notifyEmployees: "0",
    branchname: [],
  });

  //getting branches for add holiday purpose
  useEffect(() => {
    get_branches();
  }, []);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      // console.log(values)
      // action.resetForm();
      add_holiday(values);
    },
  });

  //api call for getting branch information
  const get_branches = useCallback(async () => {
    try {
      const response = await axios
        .get(`${addholiday.GLOBAL_API}AllBranches`, {
          headers: {
            Authorization: `Bearer ${addholiday.TOKEN}`,
          },
          httpAgent: addholiday.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("branches name=", response.data);
          setBranches(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  });

  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  async function add_holiday(e) {
    // console.log("selectedIds=", selectedBranchIds);
    // console.log("selectedIds=", e.branchname);
    try {
      await axios
        .post(
          `${addholiday.GLOBAL_API}Insertholiday`,
          {
            holiday_name: e.name,
            holiday_date: e.date,
            branch: e.branch_yesorno,
            branch_id: selectedBranchIds,
            description: e.description,
            notify: e.notifyEmployees,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("updated value for leave setting=", response.data);
          if (response.status === 200) {
            props.setCreate_event_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            setRenderKey(2);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // margin radio btn
  const handleBranchChange = (event) => {
    const { value: selectedNames } = event.target;
    const selectedIds = selectedNames.map(
      (name) => branches.find((branch) => branch.name === name)?.id
    );
    setSelectedBranchIds(selectedIds);
    setFieldValue("branchname", event.target.value); // Update the form field value with the array of names
  };

  // form logic for add holiday popup
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            type="text"
            name="name"
            className="text_input"
            id="outlined-basic"
            variant="outlined"
            value={values.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
            onBlur={handleBlur}
            label="Holiday Name"
          />
          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.name && touched.name ? (
              <p className="form-error">{errors.name}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            type="date"
            name="date"
            className="text_input"
            variant="outlined"
            // placeholder="First Name"
            value={values.date}
            // label="Holiday Date"
            onChange={(e) => {
              setFieldValue("date", e.target.value);
            }}
            onBlur={handleBlur}
            id="outlined-basic"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                placeholder: "", // Remove the default placeholder
              },
            }}
            label="Holiday Date"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.date && touched.date ? (
              <p className="form-error">{errors.date}</p>
            ) : null}
          </div>
        </div>
        <div className="shift_input_boxes">
          <div className="toggleboxhead">
            <h2>Branch holiday</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    // onClick={() => { get_branches() }}
                    value="1"
                    checked={values.branch_yesorno === "1"}
                    onChange={(e) => {
                      setFieldValue("branch_yesorno", e.target.value);
                    }}
                  />
                </label>
                <span>Yes</span>
              </div>
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="0"
                    checked={values.branch_yesorno === "0"}
                    onChange={(e) => {
                      setFieldValue("branch_yesorno", e.target.value);
                    }}
                  />
                </label>

                <span>No</span>
              </div>
            </div>
          </div>
        </div>
        {touched.branch_yesorno && errors.branch_yesorno ? (
          <p className="form-error">{errors.branch_yesorno}</p>
        ) : null}

        {values.branch_yesorno === "1" && (
          <>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={values.branchname}
                onChange={(e) => {
                  handleBranchChange(e);
                }}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.name}>
                    <Checkbox checked={selectedBranchIds.includes(branch.id)} />
                    <ListItemText primary={branch.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <div className="input-container mt-2">
          <TextField
            type="text"
            name="description"
            className="text_input"
            id="outlined-basic"
            // placeholder="First Name"
            value={values.description}
            label="Holiday Description"
            onChange={(e) => {
              setFieldValue("description", e.target.value);
            }}
            onBlur={handleBlur}
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.description && touched.description ? (
              <p className="form-error">{errors.description}</p>
            ) : null}
          </div>
        </div>

        <div
          className="notify"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            type="checkbox"
            value={values.notifyEmployees}
            checked={values.notifyEmployees === "1"}
            onChange={(e) => {
              setFieldValue("notifyEmployees", e.target.checked ? "1" : "0");
            }}
          />
          <p style={{ marginLeft: "2%", fontSize: "106%" }}>
            {" "}
            Notify Applicable Employees
          </p>
        </div>
        <div className="text-center mt-1 mb-3">
          <button type="submit" className="cusbtn">
            Create
          </button>
        </div>
      </form>
    </>
  );
};

//  edit holiday popup
const EditHoliday = (props) => {
  const addholiday = useSelector((state) => state.Reducer);

  // for storing branch name
  // const [branchnameset, setBranchNameSet] = useState("All Branches"); // Use state instead of a regular variable
  var branchnameset = "All branches";
  // calling branches api to get the branche name coressponding to branch id
  const get_branches = useCallback(async (branchID) => {
    try {
      const response = await axios
        .post(
          `${addholiday.GLOBAL_API}Allholiday`,
          {
            branch_id: branchID,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          branchnameset = response.data[0].branch_name;
          // console.log(
          //   "hey the data isssssssssss:",
          //   response.data[0].branch_name
          // );
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  });

  //getting holiday day on clicking edit button
  async function getholidayForEdit() {
    try {
      await axios
        .post(
          `${addholiday.GLOBAL_API}HolidayById`,
          {
            holiday_id: props.id,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then(async (response) => {
          if (response.status === 200 && response.data[0]) {
            const date = new Date(response.data[0].holiday_date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;

            // calling function for getting branch name
            await get_branches(response.data[0].branch_id).then(() => {
              if (response.data[0].branch_id != 0) {
                // console.log("the value is:", branchnameset);
                setinitialValues({
                  holidayName_edit: response.data[0].holiday_name,
                  holidayBranch_edit: branchnameset,
                  holidayDate_edit: formattedDate,
                  holidayDescription_edit: response.data[0].description,
                  holiday_id: response.data[0].holiday_id,
                });
              } else {
                setinitialValues({
                  holidayName_edit: response.data[0].holiday_name,
                  holidayDate_edit: formattedDate,
                  holidayDescription_edit: response.data[0].description,
                  holidayBranch_edit: "All Branches",
                });
              }
            });
            // console.log("values of edit data:", initialValues);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  useEffect(() => {
    getholidayForEdit();
  }, []);

  const editHolidaySchema = Yup.object({
    holidayName_edit: Yup.string(),
    holidayDate_edit: Yup.string(),
    holidayBranch_edit: Yup.string(),
    holidayDescription_edit: Yup.string(),
  });
  const [initialValues, setinitialValues] = useState({
    holidayName_edit: "",
    holidayDate_edit: "",
    holidayBranch_edit: "",
    holidayDescription_edit: "",
    holiday_id: "",
  });
  // console.log(values.holidayDate_edit);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editHolidaySchema,
    validationOnChange: true,
    onSubmit: (values, action) => {
      // action.resetForm();
      holiday_put_API(values);
      // console.log(values.holidayDate_edit);
    },
  });

  //put API for holiday edit
  async function holiday_put_API(e) {
    try {
      await axios
        .put(
          `${addholiday.GLOBAL_API}Editholiday`,
          {
            holiday_id: props.id,
            holiday_name: e.holidayName_edit,
            description: e.holidayDescription_edit,
            date: e.holidayDate_edit,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          props.setEditModal(false);
          if (response.status === 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  async function holiday_put_API(e) {
    try {
      await axios
        .put(
          `${addholiday.GLOBAL_API}Editholiday`,
          {
            holiday_id: props.id,
            holiday_name: e.holidayName_edit,
            description: e.holidayDescription_edit,
            date: e.holidayDate_edit,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          props.setEditModal(false);
          if (response.status === 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // form logic for edit holiday
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="holidayName_edit"
            value={values.holidayName_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Name"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayName_edit && touched.holidayName_edit ? (
              <p className="form-error">{errors.holidayName_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            type="date"
            name="holidayDate_edit"
            value={values.holidayDate_edit}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Date"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayDate_edit && touched.holidayDate_edit ? (
              <p className="form-error">{errors.holidayDate_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="holidayBranch_edit"
            value={values.holidayBranch_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Branch"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayBranch_edit && touched.holidayBranch_edit ? (
              <p className="form-error">{errors.holidayBranch_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="holidayDescription_edit"
            value={values.holidayDescription_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Description"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayDescription_edit &&
            touched.holidayDescription_edit ? (
              <p className="form-error">{errors.holidayDescription_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

//Toggle button for rest of the options except for all branches
const TogglebuttonHandle = (props) => {
  const [initialValues, setinitialValues] = useState({
    togglestate: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);
      // console.log(values);
      action.resetForm();
    },
  });
  // console.log("holiday:",values.togglestate);
  const addholiday = useSelector((state) => state.Reducer);

  async function holiday_put_API(id, status) {
    // console.log("the id ", id);
    // console.log("status is:", status);
    try {
      await axios
        .put(
          `${addholiday.GLOBAL_API}Editholiday`,
          {
            holiday_id: id,
            status: status,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          if (response.status === 200) {
            // console.log("toggle button is clicked");
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  const handleToggle = (e) => {
    const current = values.togglestate === "1" ? "0" : "1";
    // (isChecked_dahra===true?setCurrentStatus("1"):setCurrentStatus("0"));
    setFieldValue("togglestate", current);
    // console.log("current status",current)
    holiday_put_API(props.id, current);
  };

  async function getholidayStatus() {
    try {
      await axios
        .post(
          `${addholiday.GLOBAL_API}HolidayById`,
          {
            holiday_id: props.id,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // console.log("the toggle state is:", response.data[0].status)
            setinitialValues({
              togglestate: response.data[0].status,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  // getting holiday status for setting initial value of

  useEffect(() => {
    getholidayStatus();
  }, [])

  return (
    <div
      className="leavetypesty attendancearea mt-2"
      style={{ borderBottom: "none", justifyContent: "flex-start" }}
    >
      <div className="leavesty"></div>
      <div className="switch-toggle">
        {/* <input
          value={values.togglestate}
          type="checkbox"
          checked={values.togglestate === "1"}
          onChange={(e) => {handleToggle(e.target.value)}}
          id={`toggle-${props.name}`}
        /> */}
        <input
          // value={values.togglestate}
          type="checkbox"
          checked={values.togglestate === "1" ? true : false}
          onChange={(e) => {
            handleToggle(e.target.value);
          }}
          id={`toggle-${props.name}`}
        />
        <label htmlFor={`toggle-${props.name}`}></label>
      </div>
    </div>
  );
};

// toggle buttton for all branches
const TogglebuttonHandleTwo = (props) => {
  const addholiday = useSelector((state) => state.Reducer);

  async function holiday_put_API(status) {
    // console.log("the id ", id);
    // console.log("status is:", props.branchId);
    try {
      await axios
        .put(
          `${addholiday.GLOBAL_API}BranchholidayEdit`,
          {
            status: status,
            holiday_name: props.name,
            holiday_date: new Date(props.date).toISOString().slice(0, 10),
            branch_id: [1],
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);

          if (response.status === 200) {
            // console.log("toggle button is clicked");

            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  const [isChecked_dahra, setIsChecked_dahra] = useState(true);
  const handleToggle = (e) => {
    setIsChecked_dahra(!isChecked_dahra);
    const current = isChecked_dahra === true ? "0" : "1";
    // (isChecked_dahra===true?setCurrentStatus("1"):setCurrentStatus("0"));
    // console.log("current status",current)
    holiday_put_API(current);
  };

  return (
    <div
      className="leavetypesty attendancearea mt-2"
      style={{ borderBottom: "none", justifyContent: "flex-start" }}
    >
      <div className="leavesty"></div>
      <div className="switch-toggle">
        <input
          value={true}
          type="checkbox"
          checked={isChecked_dahra}
          onChange={(e) => {
            handleToggle(e.target.value);
          }}
          id={`toggle-${props.name}`}
        />
        <label htmlFor={`toggle-${props.name}`}></label>
      </div>
    </div>
  );
};

//edit holiday for all branch
const EditHolidayForAllBranch = (props) => {
  // storing branmch name in an array
  const data = props.allbranchesname;
  const namesArray = data.split(",").map((name) => name.trim());
  // console.log("the array is::::", namesArray);

  const data1 = props.getBranchesID;
  const namesArray1 = data1.split(",").map((name) => parseInt(name.trim()));

  // console.log("the array is for id::::", namesArray1);

  //setting dimensions for multiple branches feild
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const addholiday = useSelector((state) => state.Reducer);

  const editHolidaySchema = Yup.object({
    holidayName_edit: Yup.string(),
    holidayDate_edit: Yup.string(),
    holidayBranch_edit: Yup.array()
      .of(Yup.string())
      .required("Enter Employee Name"),
  });

  const [initialValues, setinitialValues] = useState({
    holidayName_edit: props.nameholiday,
    holidayDate_edit: props.dateholiday,
    holidayBranch_edit: [props.allbranchesname],
  });
  // console.log(values.holidayDate_edit);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editHolidaySchema,
    validationOnChange: true,
    onSubmit: (values, action) => {
      // action.resetForm();
      holiday_put_API_allbranches(values);
      // console.log(values.holidayDate_edit);
    },
  });

  //state fo storing branch information
  const [branches, setBranches] = useState([]);

  //state for storing branch id
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);

  //api call for getting branch information
  const get_branches = useCallback(async () => {
    try {
      const response = await axios
        .get(`${addholiday.GLOBAL_API}AllBranches`, {
          headers: {
            Authorization: `Bearer ${addholiday.TOKEN}`,
          },
          httpAgent: addholiday.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("branches name=", response.data);
          setBranches(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  });

  useEffect(() => {
    get_branches();
  }, []);
  //put API for holiday edit for all branches
  async function holiday_put_API_allbranches(e) {
    try {
      await axios
        .put(
          `${addholiday.GLOBAL_API}BranchHolidayEdit`,
          {
            holiday_name: props.nameholiday,
            status: "1",
            holiday_date: e.holidayDate_edit,
            branch_id: namesArray1,
          },
          {
            headers: {
              Authorization: `Bearer ${addholiday.TOKEN}`,
            },
            httpsAgent: addholiday.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          props.setEditModalforAllBranch(false);
          if (response.status === 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  const handleBranchesChange = (event) => {
    const { value: selectedNames } = event.target;
    const selectedIds = selectedNames.map(
      (name) => branches.find((branch) => branch.name === name)?.id
    );
    setSelectedBranchIds(selectedIds);
    setFieldValue("holidayBranch_edit", event.target.value); // Update the form field value with the array of names
  };

  //  async function holiday_put_API(e) {
  //   try {
  //     await axios.put(
  //       `${addholiday.GLOBAL_API}Editholiday`,
  //       {
  //         holiday_id: props.id,
  //         holiday_name: e.holidayName_edit,
  //         description: e.holidayDescription_edit,
  //         date: e.holidayDate_edit
  //       }, {
  //       headers: {
  //         Authorization: `Bearer ${addholiday.TOKEN}`,
  //       },
  //       httpsAgent: addholiday.HTTP_AGENT,
  //     }
  //     )
  //       .then((response) => {
  //         console.log("update=", response);
  //         props.setEditModal(false);
  //         if (response.status == 200) {

  //           const successMessage =
  //             response.data.message || "Something went wrong";
  //           swal({
  //             title: "Success!",
  //             text: successMessage,
  //             icon: "success",
  //           });

  //           // Close the SweetAlert after 3000 milliseconds (3 seconds)
  //           setTimeout(() => {
  //             swal.close();
  //           }, 2000);
  //         } else {

  //           const errorMessage =
  //             response.data.message || "Something went wrong";
  //           swal({
  //             title: "Failure!",
  //             text: errorMessage,
  //             icon: "error",
  //           });

  //           // Close the SweetAlert after 3000 milliseconds (3 seconds)
  //           setTimeout(() => {
  //             swal.close();
  //           }, 2000);
  //         }
  //       });
  //   } catch (e) {
  //     console.log(e);

  //     console.log(e.response.data.error);
  //     const errorMessage = e.response.data.error || "Something went wrong";
  //     swal({
  //       title: "Failure!",
  //       text: errorMessage,
  //       icon: "error",
  //     });

  //     // Close the SweetAlert after 3000 milliseconds (3 seconds)
  //     setTimeout(() => {
  //       swal.close();
  //     }, 2000);

  //   }
  // }

  // form logic for edit holiday
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="holidayName_edit"
            value={values.holidayName_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Name"
            variant="outlined"
            disabled
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayName_edit && touched.holidayName_edit ? (
              <p className="form-error">{errors.holidayName_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            type="date"
            name="holidayDate_edit"
            value={values.holidayDate_edit}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Holiday Date"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayDate_edit && touched.holidayDate_edit ? (
              <p className="form-error">{errors.holidayDate_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <FormControl sx={{ m: 0, width: 424 }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Select Branches
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={values.holidayBranch_edit || []}
              onChange={(e) => {
                handleBranchesChange(e);
              }}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              disabled
            >
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.name}>
                  <Checkbox
                    checked={values.holidayBranch_edit.includes(
                      `${branch.name}`
                    )}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.holidayBranch_edit && touched.holidayBranch_edit ? (
              <p className="form-error">{errors.holidayBranch_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

//select year poup which will appear after the user select the Year view from dropdown
const SelectYear = (props) => {
  const addholiday = useSelector((state) => state.Reducer);
  const selectYearSchema = Yup.object({
    yearView: Yup.number()
      .required("Select Year")
      .min(2000, "Year must be greater than or equal to 2000")
      .max(2030, "Year must be less than or equal to 2030")
      .integer("Year must be an integer"),
    branchview_yesorno: Yup.string().required("Select Yes or No"),
    branchview_name: Yup.number(),
  });

  const [initialValues, setinitialValues] = useState({
    yearView: "",
    branchview_yesorno: "",
    branchview_name: "0",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: selectYearSchema,
    validationOnChange: true,
    onSubmit: (values, action) => {
      // console.log("values are:", values);
      const e = {
        year: values.yearView.toString(),
        branch: parseInt(values.branchview_name),
      };
      props.get_all_holiday(e);
      // action.resetForm();
    },
  });

  //for setting  branch name from API
  const [branchesName, setBranchesName] = useState([]);

  //use effect logic for getting branch name
  useEffect(() => {
    get_branches();
  }, []);

  //api call for getting branch name for enlisting them to drop down
  const get_branches = useCallback(async () => {
    try {
      const response = await axios
        .get(`${addholiday.GLOBAL_API}AllBranches`, {
          headers: {
            Authorization: `Bearer ${addholiday.TOKEN}`,
          },
          httpAgent: addholiday.HTTP_AGENT,
        })
        .then((response) => {
          // console.log(response.data);
          setBranchesName(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  });
  // form logic for select year popup
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            type="number"
            name="yearView"
            value={values.yearView}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
              min: 2000,
              max: 2030,
            }}
            className="text_input"
            id="outlined-basic"
            label="Enter Year (2000-2030)"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.yearView && touched.yearView ? (
              <p className="form-error">{errors.yearView}</p>
            ) : null}
          </div>
        </div>
        <div className="shift_input_boxes">
          <div className="toggleboxhead">
            <h2>View Branchwise</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    // onClick={() => { get_branches() }}
                    value="1"
                    checked={values.branchview_yesorno === "1"}
                    onChange={(e) => {
                      setFieldValue("branchview_yesorno", e.target.value);
                    }}
                  />
                </label>
                <span>Yes</span>
              </div>
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="0"
                    checked={values.branchview_yesorno === "0"}
                    onChange={(e) => {
                      setFieldValue("branchview_yesorno", e.target.value);
                    }}
                  />
                </label>

                <span>No</span>
              </div>
            </div>
          </div>
        </div>
        {touched.branchview_yesorno && errors.branchview_yesorno ? (
          <p className="form-error">{errors.branchview_yesorno}</p>
        ) : null}
        {values.branchview_yesorno === "1" && (
          <>
            <div className="input-container mt-2">
              <TextField
                select
                name="branchname"
                value={values.branchview_name}
                onChange={(e) =>
                  setFieldValue("branchview_name", e.target.value)
                }
                onBlur={handleBlur}
                className="text_input"
                id="outlined-basic"
                label="Select Branch"
                variant="outlined"
              >
                {branchesName.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.branchview_name && touched.branchview_name ? (
                  <p className="form-error">{errors.branchview_name}</p>
                ) : null}
              </div>
            </div>
          </>
        )}
        <div className="text-center mt-1 mb-3">
          <button type="submit" className="cusbtn">
            View
          </button>
        </div>
      </form>
    </>
  );
};

//select Branch poup which will appear after the user select the Branch view from dropdown
const SelectBranch = (props) => {
  const addholiday = useSelector((state) => state.Reducer);
  const selectYearSchema = Yup.object({
    branch_name: Yup.number(),
  });

  const [initialValues, setinitialValues] = useState({
    branchview_name: "0",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: selectYearSchema,
    validationOnChange: true,
    onSubmit: (values, action) => {
      // console.log("values are:", values);
      const e = {
        branch: parseInt(values.branchview_name),
        year: 0,
      };
      props.get_all_holiday(e);
      action.resetForm();
    },
  });

  //for setting  branch name from API
  const [branchesName, setBranchesName] = useState([]);

  //use effect logic for getting branch name
  useEffect(() => {
    get_branches();
  }, []);

  //api call for getting branch name for enlisting them to drop down
  const get_branches = useCallback(async () => {
    try {
      const response = await axios
        .get(`${addholiday.GLOBAL_API}AllBranches`, {
          headers: {
            Authorization: `Bearer ${addholiday.TOKEN}`,
          },
          httpAgent: addholiday.HTTP_AGENT,
        })
        .then((response) => {
          // console.log(response.data);
          setBranchesName(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  });
  // form logic for select branch popup
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        {
          <>
            <div className="input-container mt-2">
              <TextField
                select
                name="branchname"
                value={values.branchview_name}
                onChange={(e) =>
                  setFieldValue("branchview_name", e.target.value)
                }
                onBlur={handleBlur}
                className="text_input"
                id="outlined-basic"
                label="Select Branch"
                variant="outlined"
              >
                {branchesName.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </TextField>
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.branch_name && touched.branch_name ? (
                  <p className="form-error">{errors.branch_name}</p>
                ) : null}
              </div>
            </div>
          </>
        }
        <div className="text-center mt-1 mb-3">
          <button type="submit" className="cusbtn">
            View
          </button>
        </div>
      </form>
    </>
  );
};

// logic for holiday view in table and value selection from dropdown
const Holiday = () => {
  const holidaySchema = Yup.object({
    holidayView: Yup.string(),
    holidayYear: Yup.string(),
    holidayBranch: Yup.string(),
    holidayName: Yup.string(),
    holidayDate: Yup.string(),
    shift: Yup.string(),
    day: Yup.string(),
    holidayDescription: Yup.string(),
  });

  const [initialValues, setinitialValues] = useState({
    holidayView: "organization",
    holidayYear: "",
    holidayBranch: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: holidaySchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      action.resetForm();
    },
  });
  // console.log(values);
  const addholiday = useSelector((state) => state.Reducer);

  // state for storing the data from api accoring to selected view
  const [datafromAPI, setDatafromAPI] = useState([]);
  const [datafromAPIforAllBranch, setDatafromAPIAllBranch] = useState([]);

  // state for add holiday popup
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // state for edit holiday popup
  const [editModal, setEditModal] = useState(false);
  const [editModalforAllBranch, setEditModalforAllBranch] = useState(false);
  const [selectYear, setSelectYear] = useState(false);
  const [selectBranch, setSelectBranch] = useState(false);
  const [getholidayID, setHolidayID] = useState();
  // state for All Branch holiday for this there is a sperate API
  const [getholidayName, setHolidayName] = useState();
  const [status, setStatus] = useState();
  const [allbranchesname, setAllbranchesname] = useState();
  //state for getting branch name for rendering it to the column of the table
  const [getBranchNameforCol, setBranchNameforCol] = useState();
  const [nameholiday, setName] = useState();
  const [dateholiday, setDateholiday] = useState();
  const [getBranchesID, setBranchesID] = useState();
  async function getBranchName(branchID) {
    if (branchID != 0) {
      try {
        // console.log("Branch id is::",branchID)
        const response = await axios
          .post(
            `${addholiday.GLOBAL_API}BranchById`,
            {
              branch_id: branchID,
            },
            {
              headers: {
                Authorization: `Bearer ${addholiday.TOKEN}`,
              },
              httpAgent: addholiday.HTTP_AGENT,
            }
          )
          .then((response) => {
            setBranchNameforCol(response.data[0].name);
            // console.log("branch name is:",getBranchNameforCol)
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
      }
    } else {
      setBranchNameforCol("All Branches");
    }
    // console.log("the branch name is::", getBranchNameforCol);
  }
  // setting columns of table accoring to data from API starts
  const data = useMemo(() => {
    if (datafromAPI) {
      return datafromAPI;
    } else {
      return [];
    }
  }, [datafromAPI]);

  // setting columns of table accoring to data from API
  const COLUMNS = [
    {
      Header: "Name",
      accessor: "holiday_name",
      Cell: ({ row }) => (
        <>
          <p>{row.original.holiday_name}</p>
        </>
      ),
    },
    {
      Header: "Date",
      accessor: "date",

      Cell: ({ row }) => (
        <>
          <p>
            {new Date(row.original.holiday_date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
          </p>
        </>
      ),
    },
    {
      Header: "Branch",
      accessor: "branch",
      Cell: ({ row }) => (
        <>
          {/* {useEffect(()=>{
            getBranchName(row.original.branch_id)
          },[row.original.branch_id])} */}

          <p>{row.original.branch_name}</p>
        </>
      ),
    },
    {
      Header: "Day",
      accessor: "days",
      Cell: ({ row }) => (
        <>
          <p>
            {new Date(row.original.date).toLocaleString("en-us", {
              weekday: "long",
            })}
          </p>
        </>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ row }) => (
        <>
          <p>{row.original.description}</p>
        </>
      ),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ row }) => (
        <div className="editbtndep">
          <button
            className="stylenonebtn"
            onClick={() => {
              setHolidayID(row.original.id);
              // popup open for edit button
              setEditModal(true);
            }}
          >
            <BiEdit
              style={{ height: "51px", width: "25px" }}
              color="var(--secondary-color)"
            />
          </button>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: ({ row }) => (
        <>
          <TogglebuttonHandle
            id={row.original.id}
            name={row.original.holiday_name}
          />
        </>
      ),
    },
  ];

  //a seperate column for the All_Branch dropdown value
  const ALL_BRANCHES_COLUMNS = [
    {
      Header: "Name",
      accessor: "holiday_name",
      Cell: ({ row }) => (
        <>
          <p>{row.original.holiday_name}</p>
        </>
      ),
    },
    {
      Header: "Date",
      accessor: "date",

      Cell: ({ row }) => (
        <>
          <p>
            {new Date(row.original.holiday_date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
          </p>
        </>
      ),
    },
    {
      Header: "Total Branches",
      accessor: "Total Branches",
      Cell: ({ row }) => (
        <>
          {/* {useEffect(()=>{
            getBranchName(row.original.branch_id)
          },[row.original.branch_id])} */}

          <p>{row.original.num_branches_with_holiday}</p>
        </>
      ),
    },
    {
      Header: "Day",
      accessor: "days",
      Cell: ({ row }) => (
        <>
          <p>
            {new Date(row.original.holiday_date).toLocaleString("en-us", {
              weekday: "long",
            })}
          </p>
        </>
      ),
    },
    {
      Header: "Branches Name",
      accessor: "Branches Name",
      Cell: ({ row }) => (
        <>
          <p>{row.original.name}</p>
        </>
      ),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ row }) => (
        <div className="editbtndep">
          <button
            className="stylenonebtn"
            onClick={() => {
              setHolidayName(row.original.holiday_name);
              setStatus(row.original.status);
              setDateholiday(row.original.holiday_date);
              // popup open for edit button
              setEditModalforAllBranch(true);
              setName(row.original.holiday_name);
              setAllbranchesname(row.original.name);
              setBranchesID(row.original.BRANCHID);
            }}
          >
            <BiEdit
              style={{ height: "51px", width: "25px" }}
              color="var(--secondary-color)"
            />
          </button>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "Status",
      Cell: ({ row }) => (
        <>
          <TogglebuttonHandleTwo
            name={row.original.holiday_name}
            date={row.original.holiday_date}
            branchId={[row.original.BRANCHID]}
          />
        </>
      ),
    },
  ];

  const columns = useMemo(() => {
    if (values.holidayView === "All_Branch") {
      return ALL_BRANCHES_COLUMNS;
    } else {
      return COLUMNS;
    }
  }, [values.holidayView]);

  // destructring values from useTable
  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;
  // use effect logic for getting the data from api when the the default value of dropdwon in organization
  useEffect(() => {
    get_all_holiday();
  }, []);
  const [view, setView] = useState();
  // function for getting the data from api when the drop down value is changed
  const select_dropdown = (e) => {
    // console.log("slected value=", e);
    // opening popup if dropdown value is Year
    if (e === "Year") {
      setSelectYear(true);
      setView("Year");
    } else if (e === "Branch") {
      setSelectBranch(true);
      setView("Branch");
    } else if (e === "Organization") {
      // console.log("hi org")
      const e = {
        year: "",
        branch: "",
      };
      get_all_holiday(e);
    } else if (e === "All_Branch") {
      // console.log("hi org")
      get_allbranch_holiday();
    }
  };

  const handleClickfornextView = (e) => {
    if (view === "Year") {
      setSelectYear(true);
    } else if (view === "Branch") {
      setSelectBranch(true);
    }
  };

  // console.log("datafromAPI=", data);
  // get holiday for all branches
  async function get_allbranch_holiday() {
    try {
      await axios
        .get(`${addholiday.GLOBAL_API}BranchHoliday`, {
          headers: {
            Authorization: `Bearer ${addholiday.TOKEN}`,
          },
          httpsAgent: addholiday.HTTP_AGENT,
        })
        .then((response) => {
          if (response.status === 200) {
            setDatafromAPI(response.data);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      setDatafromAPI("");
    }
  }

  // calling API for organization value selected from dropdown
  async function get_all_holiday(e) {
    // console.log("vaues are:",values.holidayView)
    if (values.holidayView === "Year") {
      // console.log("Year");
      try {
        await axios
          .post(
            `${addholiday.GLOBAL_API}Allholiday`,
            {
              year: e.year,
              branch_id: e.branch,
            },
            {
              headers: {
                Authorization: `Bearer ${addholiday.TOKEN}`,
              },
              httpsAgent: addholiday.HTTP_AGENT,
            }
          )
          .then((response) => {
            // console.log("the values accoring to year and branch are:", response.data);
            console.log(data);
            setSelectYear(false);
            if (response.status === 200) {
              setDatafromAPI(response.data);
            }
          });
      } catch (e) {
        setDatafromAPI("");
        setSelectYear(false);
        console.log(e);
        console.log(e.response.data.error);
      }
    } else if (values.holidayView === "Organization") {
      // console.log("Organization");
      try {
        await axios
          .post(
            `${addholiday.GLOBAL_API}Allholiday`,
            {
              year: e.year,
              branch_id: e.branch,
            },
            {
              headers: {
                Authorization: `Bearer ${addholiday.TOKEN}`,
              },
              httpsAgent: addholiday.HTTP_AGENT,
            }
          )
          .then((response) => {
            console.log(
              "the values accoring to year and branch are:",
              response.data
            );

            setDatafromAPI(response.data);
            console.log(data);
            if (response.status === 200) {
            }
          });
      } catch (e) {
        setDatafromAPI("");
        console.log(e);
        console.log(e.response.data.error);
      }
    } else if (values.holidayView === "Branch") {
      // console.log("Branch");
      try {
        await axios
          .post(
            `${addholiday.GLOBAL_API}Allholiday`,
            {
              year: e.year,
              branch_id: e.branch,
            },
            {
              headers: {
                Authorization: `Bearer ${addholiday.TOKEN}`,
              },
              httpsAgent: addholiday.HTTP_AGENT,
            }
          )
          .then((response) => {
            // console.log(
            //   "the values accoring to year and branch are:",
            //   response.data
            // );
            setSelectBranch(false);
            setDatafromAPI(response.data);
            // console.log(data);
            if (response.status === 200) {
            }
          });
      } catch (e) {
        setDatafromAPI("");
        setSelectBranch(false);
        console.log(e);
        console.log(e.response.data.error);
      }
    } else {
      // console.log("yha mat aa");
      try {
        await axios
          .post(
            `${addholiday.GLOBAL_API}Allholiday`,
            {},
            {
              headers: {
                Authorization: `Bearer ${addholiday.TOKEN}`,
              },
              httpsAgent: addholiday.HTTP_AGENT,
            }
          )
          .then((response) => {
            // console.log(
            //   "the values from the organization view of holiday are:",
            //   response.data
            // );

            if (response.status === 200) {
              // console.log("the values from the organization view of holiday are:", response.data);

              setDatafromAPI(response.data);
            } else {
              // console.log("something went wrong", response.data);
            }
          });
      } catch (e) {
        // setting data of api to empty to print no data found
        setDatafromAPI("");
        console.log(e.response.data.error);
      }
    }
  }

  return (
    <>
      {/* Edit Holiday popup starts*/}
      {editModal && (
        <div className="modal">
          <div
            onClick={() => {
              setEditModal(!editModal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Holiday</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEditModal(!editModal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <EditHoliday id={getholidayID} setEditModal={setEditModal} />
            </div>
          </div>
        </div>
      )}
      {/* Edit Holiday popup starts for all branch*/}
      {editModalforAllBranch && (
        <div className="modal">
          <div
            onClick={() => {
              setEditModalforAllBranch(!editModalforAllBranch);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Holiday</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEditModalforAllBranch(!editModalforAllBranch);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <EditHolidayForAllBranch
                id={getholidayID}
                setEditModalforAllBranch={setEditModalforAllBranch}
                nameholiday={nameholiday}
                dateholiday={new Date(dateholiday)
                  .toLocaleDateString("en-GB")
                  .split("/")
                  .reverse()
                  .join("-")}
                allbranchesname={allbranchesname}
                getBranchesID={getBranchesID}
              />
            </div>
          </div>
        </div>
      )}

      {/* Edit pop up*/}
      <div className="mainbox">
        {/* pop up */}
        {Create_event_modal && (
          <div className="modal">
            <div
              onClick={() => {
                setCreate_event_modal(!Create_event_modal);
              }}
              className="overlay"
            ></div>
            <div className="modal-content">
              <div className="modal-header">
                <h2>Add Holiday</h2>
                <button
                  className="close-modal"
                  onClick={() => {
                    setCreate_event_modal(!Create_event_modal);
                  }}
                >
                  <CgCloseR />
                </button>
              </div>
              <div className="modal-body">
                <Add_holiday setCreate_event_modal={setCreate_event_modal} />
              </div>
            </div>
          </div>
        )}
        {/* pop up*/}
        <div className="leftupperpartbtn">
          <button
            onClick={() => {
              setCreate_event_modal(!Create_event_modal);
            }}
            className="cusbtn"
          >
            <BiPlusMedical size="0.8rem" /> Add Holiday
          </button>
          {/* Add Holiday popup ends*/}

          {/* another button in case user wants to se the holidays for another value */}
          {values.holidayView === "Year" && (
            <button
              className="cusbtn"
              onClick={(e) => {
                handleClickfornextView(e);
              }}
            >
              View Another
            </button>
          )}
          {values.holidayView === "Branch" && (
            <button
              className="cusbtn"
              onClick={(e) => {
                handleClickfornextView(e);
              }}
            >
              View Another
            </button>
          )}

          {/* Drop down for selecting holiday view */}
          <select
            name="holidayView"
            id="holidayView"
            className="dropdownsty"
            value={values.holidayView}
            onChange={(e) => {
              select_dropdown(e.target.value);
              setFieldValue("holidayView", e.target.value);
              // console.log(e.target.value);
            }}
          >
            <option style={{ backgroundColor: "#2343a4" }} value="Organization">
              Organization
            </option>
            <option style={{ backgroundColor: "#2343a4" }} value="Year">
              Year
            </option>
            <option style={{ backgroundColor: "#2343a4" }} value="Branch">
              Branch
            </option>
            <option style={{ backgroundColor: "#2343a4" }} value="All_Branch">
              All Branches
            </option>
          </select>

          {/* popup for selecting year and branch */}
          {selectYear && (
            <div className="modal">
              <div
                onClick={() => {
                  setSelectYear(!selectYear);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Select Year</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setSelectYear(!selectYear);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  <SelectYear
                    setSelectYear={selectYear}
                    selectYear={selectYear}
                    datafromAPI={datafromAPI}
                    setDatafromAPI={setDatafromAPI}
                    get_all_holiday={get_all_holiday}
                  />
                </div>
              </div>
            </div>
          )}

          {/* popup for selecting year and branch */}
          {selectBranch && (
            <div className="modal">
              <div
                onClick={() => {
                  setSelectBranch(!selectBranch);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Select Branch</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setSelectBranch(!selectBranch);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  <SelectBranch
                    datafromAPI={datafromAPI}
                    setDatafromAPI={setDatafromAPI}
                    get_all_holiday={get_all_holiday}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Rendred table when the option is not All_branch */}
        {values.holidayView !== "All_Branch" && (
          <div className=" mt-3">
            {
              // rendering data of table
              datafromAPI ? (
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  <table className="mt-2" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length === 0 ? (
                        <tr>
                          <td>No data</td>
                        </tr>
                      ) : (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    style={{
                                      textalign: "center",
                                    }}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              ) : (
                <div> No data found </div>
              )
            }
          </div>
        )}

        {/* Rendred table when the option is  All_branch */}
        {values.holidayView === "All_Branch" && (
          <div className=" mt-3">
            {
              // rendering data of table
              datafromAPI ? (
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  <table className="mt-2" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length === 0 ? (
                        <tr>
                          <td>No data</td>
                        </tr>
                      ) : (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    style={{
                                      textalign: "center",
                                    }}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              ) : (
                <div> No data found </div>
              )
            }
          </div>
        )}
      </div>
    </>
  );
};

export default Holiday;
